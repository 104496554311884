import React, { useState, useEffect, useRef } from 'react';
import { getFileHandle, removeFileHandle, saveFileHandle } from '../../Services/FileSystemAccessService';
import './AutoUploadControls.scss';
import { FaFileArrowUp } from "react-icons/fa6";
import { FaPlayCircle, FaPauseCircle, FaSyncAlt } from "react-icons/fa";
import IconButton, { IconButtonType } from '../../components/IconButton/IconButton';

interface AutoUploadControlsProps {
    updateSelectedTournamentFile: (file: string) => void;
    shouldCalculateStandings: boolean;
    isFinalUpload: boolean;
    uploadTournamentFile: (shouldCalculateStandings: boolean, shouldStoreTdf: boolean, isFinalUpload: boolean) => void;
}

const AutoUploadControls: React.FC<AutoUploadControlsProps> = ({
    updateSelectedTournamentFile,
    shouldCalculateStandings,
    isFinalUpload,
    uploadTournamentFile
}) => {
    const selectedFileId = 'tournamentFile';
    const [fileHandle, setFileHandle] = useState<FileSystemFileHandle | null>(null);
    const [fileName, setFileName] = useState<string>('');
    const [comparisonFileContent, setComparisonFileContent] = useState<string>('');
    const [fileAccessGranted, setFileAccessGranted] = useState<boolean>(false);
    const [fileScanFrequency, setFileScanFrequency] = useState<number>(5);
    const [autoSmallChangeUploadFrequency, setAutoSmallChangeUploadFrequency] = useState<number>(180); // Every three minutes
    const [autoHardUploadFrequency, setAutoHardUploadFrequency] = useState<number>(900); // Every fifteen minutes
    const [isAutoUploadEnabled, setIsAutoUploadEnabled] = useState<boolean>(false);
    const [autoUploadTimeLeft, setAutoUploadTimeLeft] = useState<number>(0);
    const [featureError, setFeatureError] = useState<boolean>(false);
    const [fileReadError, setFileReadError] = useState<boolean>(false);
    const serializer = new XMLSerializer();
    const workerRef = useRef<Worker | null>(null);

    useEffect(() => {
        function uploadIfSmallChange() {
            if (fileHandle) {
                readAndUploadFile(fileHandle, false, false);
            }
        }

        function uploadIfLargeChange() {
            if (fileHandle) {
                readAndUploadFile(fileHandle, false, true);
            }
        }

        function uploadHard() {
            uploadFileImmediately();
        }

        if (fileAccessGranted && isAutoUploadEnabled) {
            uploadHard();

            if (!workerRef.current) {
                workerRef.current = new Worker(new URL('./UploadWorker.tsx', import.meta.url));
                workerRef.current.onmessage = (e: MessageEvent) => {
                    const { type, timeLeft } = e.data;

                    switch (type) {
                        case 'LARGE_CHANGE':
                            uploadIfLargeChange();
                            break;
                        case 'SMALL_CHANGE':
                            uploadIfSmallChange();
                            break;
                        case 'HARD_UPLOAD':
                            uploadHard();
                            break;
                        case 'TIME_LEFT':
                            setAutoUploadTimeLeft(timeLeft);
                            break;
                        default:
                            break;
                    }
                };
            }

            workerRef.current.postMessage({
                fileScanFrequency,
                autoSmallChangeUploadFrequency,
                autoHardUploadFrequency,
            });

            return () => {
                if (workerRef.current) {
                    workerRef.current.postMessage('STOP');
                    workerRef.current.terminate();
                    workerRef.current = null;
                }
            };
        }
    }, [fileAccessGranted, isAutoUploadEnabled, fileScanFrequency, autoSmallChangeUploadFrequency, autoHardUploadFrequency, comparisonFileContent]);

    const requestFileAccess = async () => {
        try {
            if (!(window as any).showOpenFilePicker) {
                setFeatureError(true);
                return;
            }
            const [handle] = await (window as any).showOpenFilePicker();
            setFileHandle(handle);
            await saveFileHandle(selectedFileId, handle);
            await readFile(handle);
            setFileAccessGranted(true);
            setFileName(handle.name);
            setFileReadError(false);
            setIsAutoUploadEnabled(false);
        } catch (error) {
            console.error(error);
        }
    };

    const revokeFileAccess = async () => {
        await removeFileHandle(selectedFileId);
        setFileHandle(null);
        setFileName('');
        setComparisonFileContent('');
        setFileAccessGranted(false);
    };

    const readFile = async (handle: FileSystemFileHandle) => {
        const file = await handle.getFile();
        const newFileContent = await file.text();
        updateSelectedTournamentFile(newFileContent);
    }

    const removeTimeElements = (xmlString: string): string => {
        try {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlString, "application/xml");

            const tagsToRemove = ["timeelapsed", "timeleft"];
            tagsToRemove.forEach(tag => {
                const elements = xmlDoc.getElementsByTagName(tag);
                Array.from(elements).forEach(element => {
                    element.parentNode?.removeChild(element);
                });
            });
            return serializer.serializeToString(xmlDoc);
        } catch (error) {
            console.error("Error processing XML:", error);
            return xmlString; // Return the original string if an error occurs
        }
    };

    const readAndUploadFile = async (
        handle: FileSystemFileHandle,
        uploadImmediately: boolean = false,
        onlyUploadIfLargeChange: boolean = false
    ) => {
        try {
            console.log("Reading file at " + new Date().toLocaleTimeString());
            const file = await handle.getFile();
            const newFileContent = await file.text();
            const newFileWithoutTimestamps = removeTimeElements(newFileContent);
            setFileReadError(false);

            // Check if we should upload immediately
            if (uploadImmediately) {
                console.log("Hard update, uploading file at " + new Date().toLocaleTimeString());
                processUpload(newFileContent, true);
                setComparisonFileContent(newFileWithoutTimestamps);
                return;
            }

            // Check for a significant change in file content size
            if (onlyUploadIfLargeChange) {
                console.log("Large check at " + new Date().toLocaleTimeString());
                if (Math.abs(comparisonFileContent.length - newFileWithoutTimestamps.length) > 20) {
                    console.log("Large change detected, uploading file. CC: " + (comparisonFileContent.length - newFileWithoutTimestamps.length) + " at " + new Date().toLocaleTimeString());
                    processUpload(newFileContent, true);
                    setComparisonFileContent(newFileWithoutTimestamps);
                    return;
                }
            } else {
                // Check if file content has changed
                console.log("Small check at " + new Date().toLocaleTimeString());
                const areDifferent = await areStringsDifferent(comparisonFileContent, newFileWithoutTimestamps);
                if (areDifferent) {
                    console.log("Small change detected, uploading file at " + new Date().toLocaleTimeString());
                    processUpload(newFileContent, false);
                    setComparisonFileContent(newFileWithoutTimestamps);
                    return;
                }
            }
        } catch (error) {
            console.error("Error reading or uploading file:", error);
            setFileReadError(true);
        }
    };

    const hashString = async (input: string): Promise<string> => {
        const encoder = new TextEncoder();
        const data = encoder.encode(input);
        const hashBuffer = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    };

    const areStringsDifferent = async (string1: string, string2: string): Promise<boolean> => {
        const [hash1, hash2] = await Promise.all([hashString(string1), hashString(string2)]);
        console.log("Hash1: " + hash1);
        console.log("Hash2: " + hash2);
        console.log("diff: " + (hash1 !== hash2));
        return hash1 !== hash2;
    };
    
    const uploadFileImmediately = async () => {
        if (fileHandle) {
            readAndUploadFile(fileHandle, true);
        }
    }
    
    const processUpload = (newFileContent: string, shouldStoreTdf: boolean) => {
        updateSelectedTournamentFile(newFileContent);
        uploadTournamentFile(shouldCalculateStandings, shouldStoreTdf, isFinalUpload);
    }
    
    return (
        <div className='autoUploadControlPanel'>
            <label className='glass-h2'>Automatic Upload</label>
            {!fileAccessGranted ? <label className='glass-body italic'>Supported on select systems and networks only</label> : null}
            <br/>
            {featureError ? <label className='glass-body bold'>⚠️ Configuration not supported</label> : null}
            {!fileAccessGranted ? <button className='fileSelectButton' onClick={requestFileAccess}>Grant File Access <FaFileArrowUp className="glass-button-icon-right"/></button> : null}
            {fileAccessGranted ?
            <div className='autoUploadControls'>
                <div className='fileSelectControls'>
                    <label className='glass-body bold'>./{fileName}</label>
                    <IconButton isEnabled={true} hasShadow={false} onPress={revokeFileAccess} iconType={IconButtonType.Trash} label="Remove reference to file"/>
                </div>
                <div className='timerControlsDataColumns'>
                    <div className='timerControlsData'>
                        <label className='glass-body bold'>Update frequencies</label>
                        <label className='glass-body'>File scan: 5 seconds</label>
                        <label className='glass-body'>New rounds: immediate</label>
                        <label className='glass-body'>Match results: 3 minutes</label>
                        <label className='glass-body'>Unconditional: 15 minutes</label>
                        {isAutoUploadEnabled ? <label className='glass-body bold'>Next file scan in {autoUploadTimeLeft}s</label> : null}
                        {fileReadError ? <label className='glass-body bold'>Error reading file</label> : null}
                    </div>
                    {isAutoUploadEnabled ? <FaSyncAlt className='syncIcon'/> : null}
                </div>
                <div className='timerControls'>
                    <button className='fileSelectButton' onClick={() => uploadFileImmediately()}>Upload Once <FaFileArrowUp className="glass-button-icon-right"/></button>
                    <button className='fileSelectButton' onClick={() => setIsAutoUploadEnabled(!isAutoUploadEnabled)}>
                    {isAutoUploadEnabled ? <div>Pause <FaPauseCircle className="glass-button-icon-right"/></div>
                    : <div>Start <FaPlayCircle className="glass-button-icon-right"/></div>}
                    </button>
                </div>
            </div>
            : null}
        </div>
    );
}

export default AutoUploadControls;
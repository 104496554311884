import { streamDataChannel } from "../Common/StreamDataChannel";
import { streamMatchTransfer } from "./StreamMatchTransfer";

export class streamDataTransfer {
    eventId: string;
    streamDataChannel: streamDataChannel | null;
    streamMatch: streamMatchTransfer | null;
    featureMatches: streamMatchTransfer[] | null;
    playersToWatch: string[] | null;

    constructor(eventId: string,
        streamDataChannel: streamDataChannel | null,
        streamMatch: streamMatchTransfer | null,
        featureMatches: streamMatchTransfer[] | null,
        playersToWatch: string[] | null)
        {
            this.eventId = eventId;
            this.streamDataChannel = streamDataChannel;
            this.streamMatch = streamMatch;
            this.featureMatches = featureMatches;
            this.playersToWatch = playersToWatch;
        }
}
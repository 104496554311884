import { useEffect, Fragment } from "react";
import { motion } from "framer-motion";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './PairingsDashboard.scss';
import React from 'react';
import { pairingsDashboardViewModel } from "./PairingsDashboard.ViewModel";
import { useParams } from "react-router-dom";
import { BehaviorSubject, Subscription } from "rxjs";
import PairingsList from "../PairingsList/PairingsList";
import StreamDataControls from "../StreamDataControls/StreamDataControls";
import { navbarData } from "../../components/navbar/Navbar.ViewModel";

const animationVariants = {
    offscreen: { delay: 0, opacity: 0, scale: 0.6, transition: { duration: 0.5 } },
    onscreen: { delay: 0, opacity: 1, scale: 1, transition: { duration: 0.2 } }
};

interface PairingsDashboardProps {
    navbarDataSubject: BehaviorSubject<navbarData>;
    isReadOnly: boolean;
}

const PairingsDashboard: React.FC<PairingsDashboardProps> = ({navbarDataSubject, isReadOnly}) => {
    const eventId: string = useParams().eventId ?? "";
    var round: number | null = Number(useParams().roundNumber) ?? null;

    if (round === null) {
        round = null;
    }
    const pdViewModel = new pairingsDashboardViewModel(eventId, navbarDataSubject, round, isReadOnly);

    return (
        <PairingsDashboardConstructed viewModel={pdViewModel}/>
    );
}

interface PairingsDashboardConstructedProps {
    viewModel: pairingsDashboardViewModel;
}

const PairingsDashboardConstructed: React.FC<PairingsDashboardConstructedProps> = ({ viewModel })  => {
    // animationControl = useAnimationControls()

    useEffect(() => {
        var subs: Subscription[] = [];
        return () => subs.forEach(s => s.unsubscribe());
    }, []);

    return (
        <Fragment>
            <motion.div
                initial="onscreen"
                // animate={this.animationControl}
                variants={animationVariants}
                className="pairings-content">
                    {viewModel.isReadOnly ?
                    null : <StreamDataControls
                    viewModelSubject={viewModel.streamData}
                    localStreamDataChannelSubject={viewModel.localStreamDataChannel}
                    localFeatureMatchSubject={viewModel.localFeatureMatches}
                    localPlayersToWatchSubject={viewModel.localPlayersToWatch}
                    localStreamMatchSubject={viewModel.localStreamMatch}
                    unsavedChangesSubject={viewModel.unsavedChanges}
                    roundNumber={viewModel.pairingsSubject.getValue()?.roundNumber ?? null}
                    editStreamDataChannel={viewModel.editStreamDataChannel}
                    clearStreamMatch={viewModel.clearStreamMatch}
                    clearFeatureMatches={viewModel.clearFeatureMatches}
                    editStreamMatch={viewModel.editStreamMatch}
                    editFeatureMatch={viewModel.editFeatureMatch}
                    clearPlayersToWatch={viewModel.clearPlayersToWatch}
                    uploadStreamData={viewModel.uploadStreamData}
                    resetLocalChanges={viewModel.resetLocalChanges}/>
                }
                <PairingsList
                    pairingsSubject={viewModel.pairingsSubject}
                    streamDataSubject={viewModel.streamData}
                    highlightedMatch={viewModel.highlightedMatch}
                    toggleHighlightedMatch={viewModel.toggleHighlightedMatch}
                    toggleStreamMatch={viewModel.toggleStreamMatch}
                    togglePlayerToWatch={viewModel.togglePlayerToWatch}
                    toggleFeatureMatch={viewModel.toggleFeatureMatch}/>
            </motion.div>
        </Fragment>
    );
}

export default PairingsDashboard;
export enum streamDataChannel {
        none = 0,
        channel1 = 1,
        channel2 = 2,
        channel3 = 3,
        channel4 = 4,
        channel5 = 5,
        channel6 = 6,
}

export namespace streamDataChannel {
        export function name(state: streamDataChannel): string {
                switch (state) {
                        case streamDataChannel.none:
                                return "No channel";
                        case streamDataChannel.channel1:
                                return "Channel 1";
                        case streamDataChannel.channel2:
                                return "Channel 2";
                        case streamDataChannel.channel3:
                                return "Channel 3";
                        case streamDataChannel.channel4:
                                return "Channel 4";
                        case streamDataChannel.channel5:
                                return "Channel 5";
                        case streamDataChannel.channel6:
                                return "Channel 6";
        }
        }
}

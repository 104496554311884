import React, { useEffect, useState } from 'react';
import "./IconButton.scss"
import { FaStar } from 'react-icons/fa';
import { FaRegTrashCan, FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { PiUserSwitch, PiUserSwitchFill, PiExportBold } from "react-icons/pi";
import { ImCancelCircle } from "react-icons/im";


interface IconButtonProps {
    isEnabled: boolean;
    hasShadow?: boolean;
    onPress: () => void;
    iconType: IconButtonType;
    label: string;
    showLabelAbove?: boolean;
}


export enum IconButtonType {
    Trash,
    Cancel,
    ArrowLeft,
    ArrowRight,
    SwitchPlayers,
    Export
}


const IconButton: React.FC<IconButtonProps> = ({isEnabled, hasShadow = true, onPress, iconType, label, showLabelAbove = false}) => {
    const [isEnabledState, setEnabledState] = useState<boolean>(false);


    useEffect(() => {
        setEnabledState(isEnabled);
    }, [isEnabled]);

    let icon = <FaStar />;

    switch (iconType) {
        case IconButtonType.Trash:
            icon = isEnabled ? <FaRegTrashCan className='iconbutton-icon'/> : <FaRegTrashCan className='iconbutton-icon-disabled'/>;
            break;
        case IconButtonType.Cancel:
            icon = isEnabled ? <ImCancelCircle className='iconbutton-icon'/> : <ImCancelCircle className='iconbutton-icon-disabled'/>;
            break;
        case IconButtonType.ArrowLeft:
            icon = isEnabled ? <FaArrowLeft className='iconbutton-icon'/> : <FaArrowLeft className='iconbutton-icon-disabled'/>;
            break;
        case IconButtonType.ArrowRight:
            icon = isEnabled ? <FaArrowRight className='iconbutton-icon'/> : <FaArrowRight className='iconbutton-icon-disabled'/>;
            break;
        case IconButtonType.SwitchPlayers:
            icon = isEnabled ? <PiUserSwitchFill className='iconbutton-icon'/> : <PiUserSwitch className='iconbutton-icon-disabled'/>;
            break;
        case IconButtonType.Export:
            icon = isEnabled ? <PiExportBold className='iconbutton-icon'/> : <PiExportBold className='iconbutton-icon-disabled'/>;
            break;
        default:
            break;
    }

    return (
        <div className={`iconbutton-button ${hasShadow ? '' : ' shadowless'}`} onClick={onPress}>
            <div className={`icon-label-container${showLabelAbove ? '-above' : '-below'}`}>
                <label className='icon-label'>{label}</label>
            </div>
            {icon}
        </div>
    );
};

export default IconButton;;